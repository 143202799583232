<template>
  <section>
    <b-container class="py-md-4">
      <div :class="[style['register_wrapper'], 'my-md-5 my-4 p-3']">
        <router-link
          to="/"
          class="navbar-brand font-weight-bold text-center my-6 px-0 d-none d-md-inline-block"
        >
          <img
            :src="logo"
            alt="fanpopz-logo"
            fluid
            class="register-logo"
          >
        </router-link>
        <b-row class="justify-content-start mx-0">
          <b-col
            cols="12"
            md="9"
            lg="9"
            xl="9"
          >
            <div class="pt-5 mb-5 text-left text-light">
              <h1 class="title font-weight-bold mb-4">
                Reset Password
              </h1>
              <p class="h5">
                Change password for your account
              </p>
            </div>
            <b-row :class="['mb-5 justify-content-start', style['reset-pwd-form']]">
              <b-col
                xl="7"
                lg="8"
                class="pl-0"
              >
                <div class="font-open-sans">
                  <div class="bank-search-input">
                    <b-input-group class="mb-3">
                      <b-form-input
                        id="newpassword"
                        v-model="newpassword"
                        v-validate="{ required: true, password: true}"
                        :state="validateState('newpassword')"
                        aria-describedby="input-1-live-newpassword"
                        data-vv-as="Password"
                        type="password"
                        placeholder="Enter New Password"
                        name="newpassword"
                        required
                        class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                      />
                      <b-form-invalid-feedback 
                        id="input-1-live-newpassword"
                      >
                        {{ veeErrors.first('newpassword') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </div>
                <div class="font-open-sans">
                  <div class="bank-search-input">
                    <b-input-group>
                      <b-form-input
                        id="confirmpassword"
                        v-model="confirmpassword"
                        v-validate="{ required: true, confirmed:newpassword }"
                        :state="validateState('confirmpassword')"
                        aria-describedby="input-1-live-confirmpassword"
                        data-vv-as="confirm password"
                        type="password"
                        placeholder="Enter Confirm Password"
                        name="confirmpassword"
                        required
                        class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                      />
                      <b-form-invalid-feedback 
                        id="input-1-live-confirmpassword"
                      >
                        {{ veeErrors.first('confirmpassword') }}
                      </b-form-invalid-feedback>
                    </b-input-group>                    
                  </div>
                </div>
                <b-overlay
                  :show="isLoading"
                  opacity="0.6"
                  spinner-small
                  spinner-variant="dark"
                  class="mt-4"
                >
                  <b-button
                    v-if="valid_url"
                    ref="button"
                    :disabled="isLoading"
                    variant="dark"
                    class="btn-pop text-uppercase font-weight-bold bg-golden"
                    squared
                    block
                    @click="handleNext"
                  >
                    Reset Password
                  </b-button>
                </b-overlay>
              </b-col>
            </b-row>
            <div class="text-right mb-5" />
          </b-col>
        </b-row>
      </div>
    </b-container>
  </section>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import { _sendResponse } from "../js/common-function";
import { APIUrlList } from "../js/api_url";
import logo from "../assets/logo/logo-white-text.png";
import style from "@/styles/registration.module.scss";
import { Validator } from 'vee-validate';
Validator.extend('password', {
    getMessage: "Password should have at least 1 lowercase,1 uppercase,1 number and 1 symbol.",
    validate: value => (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/).test(value)
});


export default {
    name: "ResetPassword",
    data: function() {
        return {
            style,
            url: null,
            newpassword: "",
            confirmpassword: "",
            isLoading: false, // join button click load
            valid_url: false,
            logo
        };
    },
    async created () {
        try {
            axios
                .get(API_BASE_URL + APIUrlList.RESETLINKVERIFY + '/' + this.$route.params.id)
                .then((response) => {
                    if (response.data.status === false) {
                        _sendResponse('error', response.data.message);
                        return;
                    }
                    this.isLoading = false;
                    this.valid_url = true;
                    this.email = response.data.data.email;
                })
                .catch((error) => {
                    _sendResponse('error', error.response.data.errors);
                });
        } catch (e) {
            // handle the authentication error here
            _sendResponse('error', 'Unable to process.');
        }
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleNext() {
            this.isLoading = true;
            this.$validator.validateAll().then(result => {
                if (!result) {
                    this.isLoading = false;
                    return;
                }
                this.isLoading = true;
                let formData = new FormData();
                formData.append("token", this.$route.params.id);
                formData.append("password", this.newpassword);
                formData.append("password_confirmation", this.confirmpassword);
                axios
                    .post(API_BASE_URL + APIUrlList.RESETPASSWORD, formData)
                    .then((response) => {
                        if (response.data.status === false) {
                            _sendResponse("error", response.data.message);
                            return;
                        }
                        _sendResponse("success", response.data.message);
                        this.$router.push('/');
                        this.showModal = true;
                    })
                    .catch((error) => {
                        _sendResponse("error", error.response.data.errors);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            });
        },
    },
};
</script>
